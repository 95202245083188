import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';

export const AssetClassesSection = styled.div`
  text-align: center;
  margin: 0 auto;
`;

export const AssetClassesImage = styled.img`
  width: 100%;
  max-width: 330px;
  margin: ${({ theme }) => theme.spacing.m} auto 0;
  display: flex;

  ${media.desktop`
    margin: ${({ theme }) => theme.spacing['2xl']} auto 0;
  `}

  ${media.large`
    max-width: 90%;
  `}
`;

export const AssetClassesWrapper = styled.section`
  padding: ${({ theme }) => theme.spacing.m};

  ${media.desktop`
    padding: ${({ theme }) => theme.spacing['2xl']};
  `}
`;

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

export const TabVisible = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.l};
`;
